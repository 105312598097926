<template>
  <div class="app-container calendar-list-container">
    <div v-if="!templateFlag" style="padding:20px">
      <el-table ref="prodTable" :data="prodData" border fit>
        <el-table-column
          label="模块ID"
          prop="id"
          align="center"
          min-width="20"
        />
        <el-table-column
          label="模块名称"
          prop="name"
          align="center"
          min-width="60"
        />
        <el-table-column label="操作" min-width="60" align="center">
          <template slot-scope="scope">
            <!-- <el-button
              type="primary"
              v-if="scope.row.type === 1"
              @click="gotoCharts(scope.row)"
              >数据分析</el-button
            > -->
            <el-button type="text" v-if="scope.row.type === 1"
              @click="gotoCharts(scope.row)">数据分析</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog
        :title="templateTitle"
        :visible.sync="showAddTemplate"
        width="50%"
      >
        <el-form
          ref="dataForm"
          :model="addTempForm"
          status-icon
          label-position="right"
          label-width="80px"
          style="margin-left: 15%; margin-right: 15%"
        >
          <el-form-item label="模块名称:" prop="name">
            <el-input v-model="addTempForm.name" />
          </el-form-item>
          <el-form-item label="模块名称:" prop="type">
            <div class="df aic" style="width: 80%; height: 40px">
              <el-switch
                style="display: block"
                v-model="addTempForm.type"
                active-text="追溯码"
                inactive-text="餐饮"
                :active-value="1"
                :inactive-value="0"
              >
              </el-switch>
            </div>
          </el-form-item>
          <el-form-item label="背景图:" prop="name">
            <div v-if="addTempForm.bgImg">
              <el-image
                style="width: 148px; height: 148px"
                :src="addTempForm.bgImg"
              ></el-image>
              <i
                class="el-icon-delete-solid"
                style="
                  color: red;
                  font-size: 24px;
                  margin-left: 10px;
                  cursor: pointer;
                "
                @click="handleRemove"
              ></i>
            </div>
            <div v-else>
              <el-upload
                action="#"
                list-type="picture-card"
                :on-preview="handleViewPic"
                :on-remove="handleRemove"
                :http-request="
                  (params) => {
                    uploadSectionFile(params);
                  }
                "
                :limit="1"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
            </div>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" style="margin-right: 6%">
          <el-button @click="showAddTemplate = false">取消</el-button>
          <el-button @click="addTemplate" type="primary">确定</el-button>
        </div>
      </el-dialog>
    </div>

    <div v-else>
      <div class="filter-container fl ml20">
        <el-button type="primary" @click="templateFlag = false"
          >返回上级</el-button
        >
        <el-button @click="handleCreate()">添加区域</el-button>
        <el-button
          :disabled="list == null || list.length == 0"
          type="primary"
          plain
          @click="preview()"
          >预览</el-button
        >
      </div>

      <el-table
        ref="table"
        :data="list"
        :height="tableHeight"
        element-loading-text="加载中..."
        border
        fit
        highlight-current-row
        style="width: 100%"
      >
        <el-table-column type="index" width="50" align="center" />
        <el-table-column label="区域名称" align="center">
          <template slot-scope="scope">
            <el-input
              v-show="scope.row.editable"
              v-model="scope.row.name"
              size="small"
              style="width: 300px"
            />
            <span v-show="!scope.row.editable">{{ scope.row.name }}</span>
            <el-button
              :type="scope.row.editable ? 'success' : 'primary'"
              :icon="scope.row.editable ? 'el-icon-check' : 'el-icon-edit'"
              size="small"
              circle
              @click="
                handleEdit(scope.row.editable, scope.$index, scope.row.id)
              "
            />
          </template>
        </el-table-column>
        <el-table-column width="180" label="类型" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.type | formatType1 }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.type == 0"
              type="primary"
              @click="handleRow(scope.row)"
              >设置</el-button
            >
            <el-button
              v-if="!moveFlag1 && scope.$index != clickRow1"
              @click="handleMove(scope.$index, true)"
              >移动</el-button
            >
            <el-button
              v-if="moveFlag1 && scope.$index == clickRow1"
              type="warning"
              @click="handleMove(scope.$index, false)"
              >取消</el-button
            >
            <el-button
              v-if="moveFlag1 && scope.$index != clickRow1"
              type="primary"
              @click="handleMoveTo1(scope.$index)"
              >移动到</el-button
            >
            <el-button type="danger" @click="openDeleteConfirm(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <div class="pagination-container">
        <el-pagination
          background
          :current-page="listQuery.page"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="listQuery.count"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>

      <el-dialog
        :title="textMap[dialogStatus]"
        :visible.sync="dialogFormVisible"
        width="30%"
      >
        <el-form
          ref="dataForm"
          :model="temp"
          :rules="rules"
          status-icon
          label-position="right"
          label-width="100px"
          style="margin-left: 15%; margin-right: 15%"
        >
          <el-form-item label="区域名称:" prop="name">
            <el-input v-model="temp.name" />
          </el-form-item>
          <el-form-item label="区域类型:" prop="name">
            <el-select
              v-model="temp.type"
              clearable
              placeholder="请选择"
              style="float: left"
            >
              <el-option
                v-for="item in typeOptions1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" style="margin-right: 6%">
          <el-button @click="dialogFormVisible = false">取消</el-button>
          <el-button
            v-if="dialogStatus == 'create'"
            type="primary"
            @click="createData()"
            >确定</el-button
          >
          <el-button v-else type="primary" @click="updateData()"
            >确定</el-button
          >
        </div>
      </el-dialog>

      <el-dialog title="编辑" :visible.sync="dialogVisible">
        <el-button type="primary" size="small" @click="addRow">新增</el-button>
        <el-table
          :data="tableData"
          border
          style="width: 100%; margin-top: 20px"
        >
          <el-table-column type="index" width="50" align="center" />
          <el-table-column label="模块设置">
            <template slot-scope="scope">
              <div v-if="scope.row.editable">
                <el-select
                  v-model="scope.row.type"
                  clearable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in typeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
                <el-input
                  v-if="
                    scope.row.type == 1 ||
                    scope.row.type == 2 ||
                    scope.row.type == 3
                  "
                  v-model="scope.row.name"
                  type="textarea"
                  autosize
                  placeholder="请输入组件名称"
                  style="width: 200px; min-height: 36px"
                />
                <el-input
                  v-if="scope.row.type == 2 || scope.row.type == 3"
                  v-model="scope.row.value"
                  type="textarea"
                  autosize
                  placeholder="选项(以逗号隔开)"
                  style="min-height: 36px; width: 150px"
                />
                <!-- <el-select
                                v-if="scope.row.type==4 || scope.row.type==5 || scope.row.type==11|| scope.row.type==12|| scope.row.type==13"
                                v-model="selectVal"
                                value-key="id"
                                placeholder="请选择"
                                clearable
                                @change="handleSelectChange"
                                @focus="handleRow(scope.$index)"
                              >
                                <el-option
                                  v-for="item in selectOptions"
                                  :label="item.name"
                                  :key="item.id"
                                  :value="item"
                                ></el-option>
                                </el-select>-->
                <el-button
                  type="primary"
                  size="mini"
                  @click="handleConfirm(scope.row, scope.$index)"
                  >确定</el-button
                >
              </div>
              <div v-else>
                <!-- {{scope.row}} -->
                <el-tag size="medium" type="success">{{
                  scope.row.type | formatType
                }}</el-tag>
                <el-tag
                  v-show="scope.row.name != '' && scope.row.name != null"
                  size="medium"
                  type="primary"
                  >{{ scope.row.name }}</el-tag
                >
                <el-tag
                  v-show="scope.row.value != '' && scope.row.value != null"
                  size="medium"
                  type="warning"
                  >{{ scope.row.value }}</el-tag
                >
                <el-button
                  type="primary"
                  size="mini"
                  icon="el-icon-edit"
                  @click="handleRowEdit(scope.$index)"
                />
                <el-button
                  type="danger"
                  size="mini"
                  icon="el-icon-delete"
                  @click="handleRowDelete(scope.$index)"
                />
                <el-button
                  v-if="!moveFlag && scope.$index != clickRow"
                  size="mini"
                  @click="handleClick(scope.$index, true)"
                  >选择</el-button
                >
                <el-button
                  v-if="moveFlag && scope.$index == clickRow"
                  size="mini"
                  type="warning"
                  @click="handleClick(scope.$index, false)"
                  >取消</el-button
                >
                <el-button
                  v-if="moveFlag && scope.$index != clickRow"
                  size="mini"
                  type="primary"
                  plain
                  @click="handleMoveTo(scope.$index)"
                  >移动到</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div slot="footer" class="dialog-footer" style="margin-right: 4%">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="updateData()">确定</el-button>
        </div>
      </el-dialog>

      <el-dialog title="预览界面" :visible.sync="phoneVisible" width="400px">
        <div class="phone">
          <!--          <iframe id="iframe" src="" frameborder="0" />-->
          <iframe id="iframe" :src="previewUrl" frameborder="0" />
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import axios from "../../../util/extra-api";
import api from "../../../util/api";
import pcAxios from "../../../util/api";
import { local } from "../../../util/util";
import { mapState } from "vuex";
import { mapGetters } from "vuex";
export default {
  filters: {
    // 类型转换
    formatType(type) {
      const TypeMap = {
        1: "输入框",
        2: "单项选择器",
        3: "多项选择器",
      };
      return TypeMap[type];
    },
    formatType1(type) {
      const TypeMap1 = {
        0: "输入与选择",
        1: "用户信息",
        2: "图片上传",
        3: "更多建议",
      };
      return TypeMap1[type];
    },
  },
  data() {
    return {
      templatesId: null,
      goodsId: null,
      tableKey: 0,
      list: [], // 表格数据
      total: null, // 表格数据长度
      listLoading: true, // 加载状态
      tableHeight: 450,
      goodsVisible: false,
      listQuery: {
        // 路由参数
        opt: 1,
        page: 1,
        count: 20,
        id: null,
      },
      listQuery1: {
        opt: 1,
        page: 1,
        count: 20,
      },
      total1: null,
      temp: {
        name: "",
      },
      prod_id: null,
      dialogFormVisible: false, // 弹框显示与否
      dialogStatus: "", // 弹框状态
      textMap: {
        // 弹框头部名称
        update: "编辑",
        create: "添加",
      },
      rules: {
        // 输入框规则
        name: [{ required: true, message: "必填项", trigger: "blur" }],
      },
      dialogVisible: false,
      phoneVisible: false,
      clickRow: null,
      clickRow1: null,
      moveFlag: false,
      moveFlag1: false,
      templateFlag: false,
      prodData: [],
      tableData: [],
      typeOptions: [
        {
          label: "输入框",
          value: 1,
        },
        {
          label: "单项选择器",
          value: 2,
        },
        {
          label: "多项选择器",
          value: 3,
        },
      ],
      typeOptions1: [
        {
          label: "输入与选择",
          value: 0,
        },
        {
          label: "用户信息",
          value: 1,
        },
        {
          label: "图片上传",
          value: 2,
        },
        {
          label: "更多建议",
          value: 3,
        },
      ],
      previewUrl: "",
      showAddTemplate: false,
      templateQuery: {
        page: 1,
        limit: 10,
        keyword: "",
        companyId: "",
        type: 1,
      },
      addTempForm: {
        name: "",
        type: 0,
        companyId: "",
        bgImg: "",
      },
      templateTitle: "",
      templateId: null,
      goodsTableData: [],
      goodsTotal: 0,
      currentPage: 1,
      page: {
        pager_offset: "0",
        pager_openset: "10",
        name: "",
        gtin: "",
        goods_type: "",
        type: 0,
      },
      batchPage: {
        // pager_offset: "0",
        // pager_openset: "10",
        page: 1,
        limit: 10,
        goodsId: "",
        templateId: null,
      },
      batchCurrentPage: 1,
      batchTotal: 0,
      batchList: [],
      showGoods: true,
      showAllGoods: true,
      bindQuery: {
        page: 1,
        limit: 10,
        templateId: null,
      },
      bindList: [],
      bindTotal: 0,
    };
  },
  mounted() {
    this.page = Object.assign({}, this.goodsState);
    this.page.company_id = local.get("company_id") + "";
    this.currentPage = this.page.pager_offset / 10 + 1;
    this.getProd();
    this.init(this.page);
    // this.getList();
  },
  computed: {
    ...mapGetters(["ec"]),
    ...mapState({
      goodsState: (state) => state.goodsManage.goodsState,
    }),
  },
  watch: {
    showAddTemplate(val) {
      if (!val) {
        this.resetAddTemp();
      }
    },
    dialogFormVisible(val) {
      if (!val) {
        this.temp = {
          name: "",
        };
      }
    },
  },
  methods: {
    gotoCharts(row) {
      // console.log(row);
      this.$router.push({
        name: "EvaluateChartCode",
        query: {
          templateId: row.id,
        },
      });
    },
    cancelBind(row, type) {
      let data = {};
      if (type) {
        (data.templateId = this.templatesId),
          (data.goodsId = this.batchPage.goodsId);
        data.batchNumber = row.batchNumber;
      } else {
        data.id = row.id;
      }
      axios.get("/v1/pc/evaluation/goods/cancelBind", data).then((res) => {
        if (res.code == 200) {
          this.bindQuery.page = 1;
          this.getBindGoods();
        }
      });
    },
    bindCurrentChange(page) {
      this.bindQuery.page = page;
      this.getBindGoods();
    },
    showGoodsChange(value) {
      if (!value) {
        this.bindQuery.page = 1;
        this.bindQuery.templateId = this.templatesId;
        this.getBindGoods();
      } else {
        this.page.pager_offset = "0";
        this.init(this.page);
      }
    },
    getBindGoods() {
      axios
        .get("/v1/pc/evaluation/batch/bindList", this.bindQuery)
        .then((res) => {
          if (res.code) {
            this.bindList = res.data;
            this.bindTotal = res.total;
          }
        });
    },
    changeType() {
      this.templateQuery.page = 1;
      this.getProd();
    },
    async handleBind(row) {
      //   console.log(row, this.templatesId,this.goodsId);
      axios
        .post("/v1/pc/evaluation/batch/bindGoods", {
          templatesId: this.templatesId,
          goodsInfo: [
            {
              goodsId: this.goodsId,
              batchNumber: row.batchNumber,
              batchId: row.id,
            },
          ],
        })
        .then((res) => {
          this.initBatch(this.batchPage).then((res1) => {
            console.log(res1);
            if (res1.code === 200) {
              this.batchList = res1.data;
              this.batchTotal = res1.total;
            }
          });
        });
    },
    getBatch(row) {
      this.goodsId = row.id;
      this.showGoods = false;
      this.getBatchInfo(row);
    },
    onSubmit() {
      this.currentPage = 1;
      this.page.pager_offset = "0";
      this.$store.dispatch("goodsManage/setNewPage", this.page);
      this.init(this.page);
    },
    async batchCurrentChange(page) {
      this.batchCurrentPage = page;
      this.batchPage.page = page;
      let res = await this.initBatch(this.batchPage);

      this.batchList = res.data;
      this.batchTotal = res.total;
    },
    initBatch(params) {
      return new Promise((resolve, reject) => {
        axios
          .get("/v1/pc/evaluation/goods/batchList", params)
          .then((v) => {
            resolve(v);
          })
          .catch((err) => {
            reject(v);
          });
      });
    },
    expandChange(row, expandedRows) {
      if (expandedRows.includes(row)) {
        if (expandedRows.filter((v) => v !== row).length) {
          //   console.log(expandedRows.filter((v) => v !== row));
          this.$refs["goodsTable"].toggleRowExpansion(
            expandedRows.filter((v) => v !== row)[0],
            false
          );
        }
        this.getBatchInfo(row);
      }
    },
    async getBatchInfo(row) {
      this.batchPage.page = 1;
      this.batchPage.goodsId = row.id;
      this.batchPage.templateId = this.templatesId;
      let res = await this.initBatch(this.batchPage);
      console.log(res);
      this.batchList = res.data;
      this.batchTotal = res.total;
    },
    currentChange(page) {
      this.currentPage = page;
      this.page.pager_offset = String((page - 1) * 10);
      this.$store.dispatch("goodsManage/setNewPage", this.page);
      this.init(this.page);
    },
    reInit() {
      this.page.pager_offset = "0";
      this.page.name = "";
      this.page.gtin = "";
      this.page.goods_type = "";
      this.init(this.page);
      this.showAllGoods = true;
    },
    init(params) {
      api.get("/pc/goods/list", params).then((v) => {
        this.goodsTableData = v.data.goods_list.map((m) => {
          let num = "";
          if (m.unit !== "未设置单位!" && m.unit.length > 0) {
            m.unit.forEach((item, index) => {
              if (index !== m.unit.length - 1) {
                num += item.unit_num + item.unit_name + "+";
              } else {
                num += item.unit_num + item.unit_name;
              }
            });
          } else {
            num = "无单位";
          }
          return {
            ...m,
            num,
          };
        });
        this.goodsTotal = v.data.pager_count;
      });
    },
    uploadSectionFile(params) {
      const file = params.file,
        isLt10M = file.size / 1024 / 1024 < 10;
      // 这里常规检验，看项目需求而定
      if (!isLt10M) {
        this.$message.error("只能上传图片大小小于10M");
        return;
      }
      const fileFormData = new FormData();
      fileFormData.append("file", file);
      fileFormData.append("company_id", local.get("company_id"));
      fileFormData.append(
        "user_id",
        local.get("user") && local.get("user").user_id
      );
      pcAxios
        .uploadFile("/pc/file/common", fileFormData)
        .then((resp) => {
          if (resp.code != 200) {
            this.$message.error("图片上传失败，请重新上传");
          } else {
            // console.log(resp)
            this.addTempForm.bgImg = resp.url;
          }
        })
        .catch((e) => {
          this.$message.error("图片上传失败，请重新上传");
        })
        .finally(() => {});
    },
    handleViewPic(src) {
      this.url = [src.url];
      this.showViewer = true;
    },
    handleRemove() {
      this.addTempForm.bgImg = "";
      this.$forceUpdate();
    },
    changeStatus(id, isDefault) {
      axios
        .post("/v1/pc/evaluation/update", {
          id: id,
          isDefault: isDefault ? 1 : 0,
          companyId: local.get("company_id"),
        })
        .then((res) => {
          if (res.code === 200) {
            this.getProd();
          }
        });
    },
    addTemplate() {
      this.addTempForm.companyId = local.get("company_id");
      if (this.templateTitle == "添加模板") {
        axios.post("/v1/pc/evaluation/save", this.addTempForm).then((res) => {
          if (res.code === 200) {
            this.getProd();
            this.showAddTemplate = false;
          }
        });
      } else {
        let data = {
          ...this.addTempForm,
          id: this.templateId,
        };
        axios.post("/v1/pc/evaluation/update", data).then((res) => {
          if (res.code === 200) {
            this.getProd();
            this.showAddTemplate = false;
          }
        });
      }
      this.resetAddTemp();
    },
    resetAddTemp() {
      this.addTempForm = {
        companyId: "",
        name: "",
        type: "",
      };
    },
    handleCreateTemplate(index, id, name, img, type) {
      if (index === 1) {
        this.addTempForm.name = name;
        this.templateId = id;
        this.addTempForm.bgImg = img;
        this.addTempForm.type = type;
      } else {
        this.addTempForm.type = 0;
      }
      this.templateTitle = index === 0 ? "添加模板" : "修改模板";
      this.showAddTemplate = true;
    },
    getProd() {
      this.templateQuery.companyId = local.get("company_id");
      axios.get("/v1/pc/evaluation/list", this.templateQuery).then((res) => {
        this.tableHeight =
          window.innerHeight - this.$refs.prodTable.$el.offsetTop - 85;
        this.prodData = res.data.map((m) => {
          return {
            ...m,
            isDefault: m.isDefault == 1,
          };
        });
        this.total1 = res.total;
      });
    },
    getList() {
      this.listLoading = true;
      this.listQuery.id = this.prod_id;
      axios
        .get("/v1/pc/evaluation/titleList", this.listQuery)
        .then((response) => {
          this.tableHeight =
            window.innerHeight - this.$refs.table.$el.offsetTop - 85;
          const items = response.data;
          this.list = items.map((m) => {
            return {
              ...m,
              editable: false,
            };
          });
          this.list.sort((a, b) => a.sort - b.sort);
          this.total = response.total;
          this.listLoading = false;
        });
    },
    handleDeleteTemplate(id) {
      axios.delete("/v1/pc/evaluation/delete/" + id).then((res) => {
        if (res.code === 200) {
          this.getProd();
        }
      });
    },
    handleTemplate(id) {
      this.prod_id = id;
      this.listQuery.prod_id = id;
      this.templateFlag = true;
      this.getList();
    },
    // 表格单页数据显示量
    handleSizeChange(val) {
      this.listQuery.count = val;
      this.getList();
    },
    // 换页
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    resetTemp() {
      this.temp = {
        name: "",
        content: "",
        type: "",
        area_order: "",
        prod_id: null,
        id: null,
      };
    },
    handleCreate() {
      //this.resetTemp()
      this.dialogStatus = "create";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
    },
    createData() {
      this.temp.evaluateId = this.prod_id;
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          axios
            .post("/v1/pc/evaluation/saveTitle", this.temp)
            .then((response) => {
              this.$notify({
                title: "成功",
                message: "新增成功",
                type: "success",
                duration: 2000,
              });
              this.dialogFormVisible = false;
              this.getList();
            })
            .catch(() => {
              this.$notify({
                title: "失败",
                message: "新增失败",
                type: "error",
                duration: 2000,
              });
            });
        } else {
          return false;
        }
      });
    },
    handleEdit(flag, index, id) {
      if (flag) {
        this.temp.id = id;
        this.temp.name = this.list[index].name;
        axios
          .post("/v1/pc/evaluation/updateTitle", {
            id: id,
            name: this.list[index].name,
          })
          .then((response) => {
            this.getList();
          });
      }
      this.list[index].editable = !this.list[index].editable;
    },
    handleRow(row) {
      this.getContentData(row);
      this.dialogVisible = true;
    },
    handleRowEdit(index) {
      this.tableData[index].editable = true;
      if (
        this.tableData[index].type == 3 &&
        this.tableData[index].value != ""
      ) {
        this.tableData[index].value = this.tableData[index].value.join(",");
      }
    },
    handleRowDelete(index) {
      this.tableData.splice(index, 1);
    },
    getContentData(data) {
      this.temp.id = data.id;
      this.tableData = [];
      this.tableData = JSON.parse(data.content);
    },
    addRow() {
      if (!this.tableData) this.tableData = [];
      this.tableData.push({
        type: null,
        name: "",
        value: "",
        editable: true,
      });
    },
    handleConfirm(row, rowIndex) {
      this.tableData[rowIndex].editable = false;
      if (
        this.tableData[rowIndex].type == 2 ||
        (this.tableData[rowIndex].type == 3 &&
          this.tableData[rowIndex].value != "")
      ) {
        this.tableData[rowIndex].value =
          this.tableData[rowIndex].value.split(/,|，/);
      }
    },
    // 编辑数据
    updateData() {
      this.temp.content = this.tableData;
      axios
        .post("/v1/pc/evaluation/finishedContent", this.temp)
        .then((response) => {
          this.dialogVisible = false;
          this.getList();
          this.$notify({
            title: "成功",
            message: "更新成功",
            type: "success",
            duration: 2000,
          });
          this.temp.content = null;
        })
        .catch(() => {
          this.$notify({
            title: "失败",
            message: "新增失败",
            type: "error",
            duration: 2000,
          });
        });
    },
    handleClick(index, flag) {
      if (flag) {
        this.clickRow = index;
        this.moveFlag = flag;
      } else {
        this.clickRow = null;
        this.moveFlag = flag;
      }
    },
    // 移动到
    handleMoveTo(index) {
      if (this.clickRow > index) {
        this.tableData.splice(index, 0, this.tableData[this.clickRow]);
        this.tableData.splice(this.clickRow + 1, 1);
      } else {
        this.tableData.splice(index + 1, 0, this.tableData[this.clickRow]);
        this.tableData.splice(this.clickRow, 1);
      }
      this.clickRow = null;
      this.moveFlag = false;
    },
    handleMove(index, flag) {
      if (flag) {
        this.clickRow1 = index;
        this.moveFlag1 = flag;
      } else {
        this.clickRow1 = null;
        this.moveFlag1 = flag;
      }
    },
    // 移动到
    handleMoveTo1(index) {
      if (this.clickRow1 > index) {
        this.list.splice(index, 0, this.list[this.clickRow1]);
        this.list.splice(this.clickRow1 + 1, 1);
      } else {
        this.list.splice(index + 1, 0, this.list[this.clickRow1]);
        this.list.splice(this.list, 1);
      }
      let num = 1;
      const a = [];
      this.list.forEach((listItem) => {
        a.push({ id: listItem.id, sort: num });
        num++;
      });
      axios.post("/v1/pc/evaluation/sort", { list: a }).then((response) => {
        this.getList();
      });
      this.clickRow1 = null;
      this.moveFlag1 = false;
    },
    handleDelete(row) {
      axios
        .delete("/v1/pc/evaluation/deleteTitle/" + row.id)
        .then((response) => {
          this.getList();
          this.$notify({
            title: "成功",
            message: "删除成功",
            type: "success",
            duration: 2000,
          });
        })
        .catch(() => {
          this.$notify({
            title: "失败",
            message: "删除失败",
            type: "error",
            duration: 2000,
          });
        });
    },
    // 打开删除操作框
    openDeleteConfirm(row) {
      this.$confirm("确定删除此条数据?", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        center: true,
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            this.handleDelete(row);
            done();
          } else {
            done();
          }
        },
      }).catch(() => {
        this.$notify({
          title: "取消",
          message: "已取消删除",
          type: "info",
          duration: 1000,
        });
      });
    },
    preview() {
      // this.previewUrl = "http://e.esunadv.com/goods_dist/#/evaluate?company_id="+local.get('company_id')
      this.previewUrl =
        "https://e.esunadv.com/goods_dist/#/evaluate?company_id=" +
        local.get("company_id");
      this.phoneVisible = true;
      //let iframe = document.querySelector("#iframe");
      //this.populateIframe(iframe, [["QrEncode", 'test'],['Access-Control-Allow-Origin','*'],['Content-Type','application/x-www-form-urlencoded']],this.previewUrl);
    },
    populateIframe(iframe, headers, url) {
      let xhr = new XMLHttpRequest();
      xhr.open("GET", url);
      xhr.responseType = "blob";
      headers.forEach((header) => {
        xhr.setRequestHeader(header[0], header[1]);
      });
      xhr.onreadystatechange = () => {
        if (xhr.readyState === xhr.DONE) {
          if (xhr.status === 200) {
            iframe.src = URL.createObjectURL(xhr.response);
          }
        }
      };
      xhr.send();
    },
  },
};
</script>
<style lang="scss">
.filter-container {
  padding-bottom: 10px;

  .filter-item {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 10px;
  }

  // .phone {
  //   /*!*height: 611px;*!*/
  //   /*width: 360px;*/
  //   /*border-radius: 55px;*/
  //   /*border-radius: 4px;*/
  //   /*box-shadow: 0 0 9px 5px #eee;*/
  //   /*position: relative;*/
  // }
}
.phone .bg {
  width: 360px;
  height: 64px;
  padding-top: 5px;
  background: -webkit-linear-gradient(rgba(55, 55, 55, 0.98), #545456);
  background: linear-gradient(rgba(55, 55, 55, 0.98), #545456);
  text-align: center;
}
.phone iframe {
  border: none;
  width: 360px;
  height: 542px;
  padding: 0;
  box-sizing: border-box;
}
.phone .mask {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  filter: alpha(opacity=0);
  opacity: 0;
  background: #ffffff;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.aic {
  align-items: center;
}
.batch-item {
  background-color: #409eff;
  color: #ffffff;
  border-radius: 10px;
  margin: 10px;
  padding: 10px 20px;
}
.jcb {
  justify-content: space-between;
}
</style>
